import { Link } from 'gatsby'
import React, { useState } from 'react'
import Image from '~/components/image'
import { FacebookIcon, InstaIcon } from '../../images'
import ShareButton from '../shareButton'
import { Span } from '../text'
import {
  Icons,
  Img,
  Li,
  Line,
  LineWrapper,
  MenuContainer,
  StyleLink,
  Ul,
  Wrapper,
} from './style'

const menuList = [
  {
    link: '/',
    title: 'ホーム',
    titleEn: 'Home',
  },
  {
    link: '/about',
    title: '味菜自然豚',
    titleEn: 'About',
  },
  {
    link: '/pork',
    title: 'お肉の購入',
    titleEn: 'Pork',
  },
  {
    link: '/merchant',
    title: '小売・飲食業の方へ',
    titleEn: 'Merchant',
  },
  {
    link: '/owner',
    title: 'オーナー制度',
    titleEn: 'Owner',
  },
  {
    link: '/store',
    title: '取扱店舗',
    titleEn: 'Store',
  },

  {
    link: '/contact',
    title: 'お問い合わせ',
    titleEn: 'Contact',
  },
  {
    link: '/pictureBook',
    title: '食育絵本',
    titleEn: 'PictureBook',
  },
]

const Menu = () => {
  const [active, setState] = useState(false)
  const handleClick = () => {
    setState(!active)
  }
  return (
    <>
      <MenuContainer active={active}>
        <LineWrapper onClick={handleClick}>
          <Line active={active} />
          <Line active={active} />
          <Line active={active} />
        </LineWrapper>
        <StyleLink to={'/'}>
          <Img active={active}>
            <Image fileName={'headerText.png'} alt={'ロゴ'} />
          </Img>
        </StyleLink>
        <div style={{ width: 50 }} />
      </MenuContainer>
      <Wrapper active={active}>
        <Ul>
          {menuList.map((menu, i) => {
            return (
              <Link to={menu.link} key={i} onClick={handleClick}>
                <Li>
                  <Span size={2} serif mr={25} color={'white'}>
                    {menu.titleEn}
                  </Span>
                  <Span size={1.4} color={'white'}>
                    {menu.title}
                  </Span>
                </Li>
              </Link>
            )
          })}
        </Ul>
        {/* <CartSpan> </CartSpan> */}
        <Icons>
          <a
            href={'https://www.instagram.com/ajisaishizenmura/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstaIcon color={'white'} />
          </a>
          <a
            href={'https://www.facebook.com/ajisaisizennmurahappypork/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon color={'white'} />
          </a>
          <ShareButton color={'white'} />
        </Icons>
      </Wrapper>
    </>
  )
}
export default Menu
