import styled from '@emotion/styled'
import { Link } from 'gatsby'
import color from '../color'
import { Col, Row } from '../row'
import { Span } from '../text'

export const Wrapper = styled(Col)<{ active: boolean }>`
  position: fixed;
  top: ${(props) => (props.active ? 0 : '-130%')};
  width: calc(100% - 40px);
  height: 100vh;
  padding: 60px 20px;
  background-color: ${color.green};
  z-index: 100;
  transition: all 0.5s;
  @media screen and (min-width: 768px) {
    width: calc(50% - 160px);
    padding: 80px;
    top: 0;
    left: ${(props) => (props.active ? 0 : '-750px')};
  }
`

export const Ul = styled.ul`
  width: calc(100% - 50px);
  margin-left: 10px;
  padding: 20px 0 30px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`

export const Li = styled.li`
  align-items: baseline;
  margin-bottom: 20px;
  transition: 0.4s all;
  cursor: pointer;
  :hover {
    opacity: 0.4;
  }
`

export const Icons = styled(Row)`
  margin-top: 30px;
  padding-left: 40px;
  gap: 30px;
`

export const CartSpan = styled(Span)`
  font-size: 1rem;
  color: white;
  font-weight: 600;
  padding: 50px 0 40px 40px;
  transition: 0.4s all;
  cursor: pointer;
  :hover {
    opacity: 0.4;
  }
`

export const Img = styled.div<{ active: boolean }>`
  width: 90px;
  height: 20px;
  margin: auto;
  opacity: ${(props) => (props.active ? 0 : '100%')};
  @media screen and (min-width: 1024px) {
    opacity: 0;
  }
`

export const MenuContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  position: fixed;
  box-sizing: border-box;
  z-index: 120;
  background-color: ${(props) => (props.active ? `${color.green}` : 'white')};
  transition: all 0.4s;
  align-items: center;
  @media screen and (min-width: 1024px) {
    width: 80px;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${(props) =>
      props.active ? `${color.green}` : 'inherit'};
  }
`

export const Line = styled.span<{ active: boolean }>`
  position: absolute;
  left: 20px;
  width: 20px;
  height: 2px;
  background-color: ${(props) => (props.active ? 'white' : '#222')};
  border-radius: 5px;
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  @media screen and (min-width: 1024px) {
    left: 30px;
  }
  :nth-of-type(1) {
    top: 36%;
    transform: ${(props) => props.active && 'translateY(10px) rotate(-45deg)'};
    @media screen and (min-width: 1024px) {
      top: 48.95%;
      transform: ${(props) => props.active && 'translateY(4px) rotate(-45deg)'};
    }
  }
  :nth-of-type(2) {
    top: 50%;
    opacity: ${(props) => (props.active ? 0 : 1)};
    @media screen and (min-width: 1024px) {
      top: 48%;
      opacity: ${(props) => (props.active ? 0 : 1)};
    }
  }
  :nth-of-type(3) {
    top: 64%;
    transform: ${(props) => props.active && 'translateY(-4px) rotate(45deg)'};
    @media screen and (min-width: 1024px) {
      top: 49.95%;
      transform: ${(props) => props.active && 'translateY(-4px) rotate(45deg)'};
    }
  }
`

export const StyleLink = styled(Link)`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const LineWrapper = styled.div`
  cursor: pointer;
  height: 50px;
  width: 115px;
`
