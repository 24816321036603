import { Link } from 'gatsby'
import React from 'react'
import Image from '~/components/image'
import { CartIcon, FacebookIcon, InstaIcon } from '~/images'
import { Logo } from '~/page-sections/home/top/style'
import { Col } from '../row'
import ShareButton from '../shareButton'
import { Span } from '../text'
import { ColCenter } from '~/components/row'
import {
  Buy,
  BuyCol,
  BuyText,
  Container,
  FooterText,
  Icons,
  Img,
  Li,
  Menu,
  Ul,
} from './style'

const links = [
  '/',
  '/about',
  '/pork',
  '/merchant',
  '/owner',
  '/store',
  '/contact',
]
const texts = ['Home', 'About', 'Pork', 'Merchant', 'Owner', 'Store', 'Contact']

const Footer = () => {
  return (
    <ColCenter>
      <Link style={{ width: '100%' }} to="/pork">
        <Buy>
          <BuyCol>
            <CartIcon color={'white'} />
            <BuyText>お肉を購入する</BuyText>
          </BuyCol>
          <Img>
            <Image fileName={'sausageWiener.jpg'} alt={'ウインナー'} />
          </Img>
        </Buy>
      </Link>
      <Container>
        <Menu>
          <Col style={{ width: '100%' }}>
            <Span serif size={2.4} mb={20}>
              味菜自然村
            </Span>
            <Span mb={10}>
              〒859-6121 長崎県佐世保市江迎町奥川内328-1
              <a
                href={
                  'https://www.google.com/maps/place/%E3%80%92859-6121+%E9%95%B7%E5%B4%8E%E7%9C%8C%E4%BD%90%E4%B8%96%E4%BF%9D%E5%B8%82%E6%B1%9F%E8%BF%8E%E7%94%BA%E5%A5%A5%E5%B7%9D%E5%86%85%EF%BC%93%EF%BC%92%EF%BC%98%E2%88%92%EF%BC%91/@33.3047968,129.6597169,17z/data=!4m13!1m7!3m6!1s0x356a8d50beaac269:0xa5c0dd9e759416f9!2z44CSODU5LTYxMjEg6ZW35bSO55yM5L2Q5LiW5L-d5biC5rGf6L-O55S65aWl5bed5YaF77yT77yS77yY4oiS77yR!3b1!8m2!3d33.3047968!4d129.6619056!3m4!1s0x356a8d50beaac269:0xa5c0dd9e759416f9!8m2!3d33.3047968!4d129.6619056'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                （GoogleMaps）
              </a>
            </Span>
            <Span>TEL&FAX.&ensp;0956-66-8833</Span>
            <Icons>
              {/* <Link to={'/cart'}>
                <CartIcon />
              </Link> */}
              <a
                href={'https://www.instagram.com/ajisaishizenmura/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstaIcon />
              </a>
              <a
                href={'https://www.facebook.com/ajisaisizennmurahappypork/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <ShareButton />
            </Icons>
            <Ul>
              {texts.map((text, i) => {
                return (
                  <Link to={links[i]} key={i}>
                    <Li>{text}</Li>
                  </Link>
                )
              })}
            </Ul>
          </Col>
          <Link to="/">
            <Logo style={{ marginRight: 0 }}>
              <Image fileName={'ajisaiLogo.png'} alt={'ロゴ'} />
            </Logo>
          </Link>
        </Menu>
      </Container>
      <a
        href="https://lyact.notion.site/6145801949974c68a4348ade66f4c394"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Span size={1.2} mt={30}>
          特定商取引法に基づく表記
        </Span>
      </a>
      <FooterText>Copyright © All rights reserved.</FooterText>
    </ColCenter>
  )
}
export default Footer
