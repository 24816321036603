import styled from '@emotion/styled'
import Arrow from '~/svgs/arrow.svg'
import Cart from '~/svgs/cart.svg'
import Facebook from '~/svgs/facebook.svg'
import Fork from '~/svgs/fork.svg'
import Insta from '~/svgs/instagram.svg'
import People from '~/svgs/people.svg'
import Phone from '~/svgs/phone.svg'
import color from '../components/color'

interface IconProps {
  size?: number
  src?: string
  color?: string
  mr?: number
}

export const CartIcon = styled(Cart)`
  width: ${(props: IconProps) => props.size}px;
  fill: ${(props: IconProps) => props.color};
  margin-right: ${(props: IconProps) => props.mr}px;
`
CartIcon.defaultProps = {
  size: 18,
  color: `${color.black}`,
}

export const InstaIcon = styled(Insta)`
  width: ${(props: IconProps) => props.size}px;
  fill: ${(props: IconProps) => props.color};
  cursor: pointer;
`
InstaIcon.defaultProps = {
  size: 18,
  color: `${color.black}`,
}

export const FacebookIcon = styled(Facebook)`
  width: ${(props: IconProps) => props.size}px;
  height: ${(props: IconProps) => props.size}px;
  fill: ${(props: IconProps) => props.color};
  cursor: pointer;
`
FacebookIcon.defaultProps = {
  size: 18,
  color: `${color.black}`,
}

export const RightIcon = styled(Arrow)`
  width: ${(props: IconProps) => props.size}px;
  height: ${(props: IconProps) => props.size}px;
  fill: ${(props: IconProps) => props.color};
`
RightIcon.defaultProps = {
  size: 18,
  color: `${color.gray2}`,
}

export const PeopleIcon = styled(People)`
  width: 130px;
  height: 130px;
  fill: white;
  margin-bottom: 20px;
  stroke: ${color.green};
  stroke-width: 2px;
`

export const PhoneIcon = styled(Phone)`
  width: 130px;
  height: 130px;
  margin-bottom: 20px;
  stroke: ${color.green};
  stroke-width: 4px;
  path {
    fill: white;
  }
`

export const ForkIcon = styled(Fork)`
  width: 130px;
  height: 130px;
  fill: white;
  margin-bottom: 20px;
  stroke: ${color.green};
  stroke-width: 8px;
`

export const TopImg = styled.div`
  width: 100%;
  height: 350px;
  @media screen and (min-width: 768px) {
    height: 600px;
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% - 160px);
    height: 500px;
    margin: 0 80px;
  }
`

export const MainImg = styled.div`
  width: 100%;
  height: 300px;
  margin: 100px 0;
  @media screen and (min-width: 768px) {
    margin: 150px 0;
  }
  @media screen and (min-width: 1024px) {
    height: 500px;
  }
`
