exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-merchant-tsx": () => import("./../../../src/pages/merchant.tsx" /* webpackChunkName: "component---src-pages-merchant-tsx" */),
  "component---src-pages-owner-credits-tsx": () => import("./../../../src/pages/owner/credits.tsx" /* webpackChunkName: "component---src-pages-owner-credits-tsx" */),
  "component---src-pages-owner-index-tsx": () => import("./../../../src/pages/owner/index.tsx" /* webpackChunkName: "component---src-pages-owner-index-tsx" */),
  "component---src-pages-picture-book-tsx": () => import("./../../../src/pages/pictureBook.tsx" /* webpackChunkName: "component---src-pages-picture-book-tsx" */),
  "component---src-pages-pork-boronia-tsx": () => import("./../../../src/pages/pork/boronia.tsx" /* webpackChunkName: "component---src-pages-pork-boronia-tsx" */),
  "component---src-pages-pork-index-tsx": () => import("./../../../src/pages/pork/index.tsx" /* webpackChunkName: "component---src-pages-pork-index-tsx" */),
  "component---src-pages-pork-wiener-tsx": () => import("./../../../src/pages/pork/wiener.tsx" /* webpackChunkName: "component---src-pages-pork-wiener-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/signUp.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */)
}

