import styled from '@emotion/styled'
import { ColCenter, RowCenter } from '../row'

export const MenuContainer = styled(RowCenter)`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    position: fixed;
    width: 80px;
    height: 100vh;
    top: 0;
    right: 0;
    box-sizing: border-box;
  }
`

export const Icons = styled(ColCenter)`
  gap: 30px;
`
