import styled from '@emotion/styled'

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const RowCenter = styled(Row)`
  justify-content: center;
`

export const RowBt = styled(Row)`
  justify-content: space-between;
`

export const Col = styled(Row)`
  flex-direction: column;
  align-items: flex-start;
`

export const ColCenter = styled(Col)`
  align-items: center;
`
export const Spans = styled(Col)`
  @media screen and (min-width: 768px) {
    max-width: 500px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 800px;
  }
`
