import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ fileName, alt }: { fileName: string; alt: string }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const image = data.images.edges.find((n) => {
          return n.node.relativePath.includes(fileName)
        })
        if (!image) {
          return null
        }
        return (
          <Img
            fluid={image.node.childImageSharp.fluid}
            alt={alt}
            style={{ height: 'inherit' }}
          />
        )
      }}
    />
  )
}
export default Image
