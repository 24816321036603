import PropTypes from 'prop-types'
import React from 'react'
import Footer from '../components/footer'
import Menu from '../components/leftNav'
import RightNav from '../components/rightNav'
import './layout.css'
import './loader.css'

const Layout = ({ children }) => (
  <>
    <header />
    <Menu />
    <main>{children}</main>
    <RightNav />
    <footer>
      <Footer />
    </footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
