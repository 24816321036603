import styled from '@emotion/styled'
import color from '../color'
import { Col, ColCenter, Row } from '../row'

export const Buy = styled(Row)`
  margin: 100px 25px 40px;
  transition: all 0.4s;
  :hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    margin: 150px 15% 40px;
  }
  @media screen and (min-width: 1280px) {
    margin: 200px 15% 70px;
  }
`

export const BuyCol = styled(ColCenter)`
  flex: 1;
  height: 100px;
  background-color: ${color.green};
  justify-content: center;
  @media screen and (min-width: 768px) {
    height: 200px;
  }
`

export const BuyText = styled.span`
  color: white;
  padding: 10px 10px 0;
  font-size: 1rem;
  font-family: 'Noto Serif JP';
  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }
`

export const Img = styled.div`
  flex: 2;
  height: 100px;
  border-radius: 0 10px 10px 0;
  @media screen and (min-width: 768px) {
    height: 200px;
    border-radius: 0 15px 15px 0;
  }
`

export const Menu = styled(ColCenter)`
  width: calc(100% - 50px);
  padding: 40px 25px;
  @media screen and (min-width: 768px) {
    width: calc(100% - 30%);
    padding: 40px 15%;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-end;
  }
`

export const Icons = styled(Row)`
  margin-top: 40px;
  gap: 30px;
`

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 0 50px;
  @media screen and (min-width: 1024px) {
    margin: 40px 0 0;
    flex-direction: row;
  }
`

export const Li = styled.li`
  font-size: 2rem;
  padding: 10px 0;
  border-bottom: 4px dotted #d8d8d8;
  transition: all 0.4s;
  font-family: 'Noto Serif JP';
  @media screen and (min-width: 1024px) {
    padding-right: 30px;
    border: none;
  }
`

export const FooterText = styled.p`
  width: 100%;
  font-size: 1.2rem;
  color: #999;
  text-align: center;
  margin-bottom: 50px;
  font-family:sans-serif;
  @media screen and (min-width: 1024px) {
    margin-bottom: 80px;
  }
`

export const Container = styled(Col)`
  width: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
  }
`
