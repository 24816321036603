import styled from '@emotion/styled'
import React from 'react'
import Color from '../color'
import { Span } from '../text'

const ShareButton = ({ color = Color.black }: { color?: string }) => {
  if (typeof window === `undefined`) return null
  if (!navigator.canShare) return null
  const handleShare = () => {
    //shareはhttpsのみで動作するため
    if (location.protocol !== 'https:') return
    navigator.share({
      text: '標高230m。自然豊かな放牧養豚場。味菜自然村',
      url: 'https://www.ajisaishizenmura.com',
    })
  }
  return (
    <ShareText onClick={handleShare} color={color}>
      SHARE
    </ShareText>
  )
}
export default ShareButton

export const ShareText = styled(Span)`
  font-size: 1rem;
  transition: 0.4s all;
  font-family: 'Noto Serif JP';
  cursor: pointer;
  :hover {
    opacity: 0.4;
  }
`
