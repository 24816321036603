import styled from '@emotion/styled'
import { ColCenter, Row } from '~/components/row'
import { H1 } from '~/components/text'

export const Wrapper = styled(ColCenter)`
  position: relative;
  width: 100%;
  height: 100vh;
  @media screen and (min-width: 1024px) {
  height: 100vh;
  overflow: hidden;
  }

`

export const Title = styled(H1)`
  position: absolute;
  top: 16%;
  right: 15px;
  font-weight: 300;
  writing-mode: vertical-rl;
  background-color: white;
  padding: 20px 10px 10px;
  letter-spacing: 15px;
  font-family: 'Noto Serif JP';
  @media screen and (min-width: 1024px) {
    top: 210px;
    right: 180px;
  }
`

export const Title2 = styled(Title)`
  top: 16%;
  right: 24%;
  padding: 20px 10px 0px;
  @media screen and (min-width: 1024px) {
    top: 210px;
    right: 260px;
  }
`

export const Img = styled.div`
  width: 100%;
  height: 100%;
  @media screen and (min-width: 1024px) {
    top:0;
    width: calc(100% - 160px);
    height: calc(100% - 80px);
  }
`

export const Logo = styled.div`
  width: 110px;
  margin-right: 20px;
`

export const HeaderRow = styled(Row)`
  display: none;
  @media screen and (min-width: 1024px) {
    display: flex;
    width: 850px;
    margin: 40px 0px 30px;
    justify-content: space-between;
    align-items: flex-end;
  }
`
