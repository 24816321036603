import styled from '@emotion/styled'
import color from './color'

interface TextProps {
  width?: string
  size?: number
  weight?: number
  color?: string
  align?: string
  ellipsis?: number
  serif?: boolean
  mt?: number //margin-top
  mr?: number //margin-right
  mb?: number //margin-bottom
  ml?: number //margin-left
  padding?: string
  overflow?: string
}

const getEllipsis = (ellipsis?: number) => {
  if (ellipsis === 1) {
    return `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    `
  } else if (ellipsis! > 1) {
    return `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: ${ellipsis};
    `
  }
}

// export const P1 = styled.p`
//   font-size: 1.6rem;
//   font-weight: 400;
//   color: ${(props: TextProps) => props.color};
//   text-align: ${(props: TextProps) => props.align};
//   font-family: ${(props) => props.serif && 'Noto Serif JP'};
//   margin-top: ${(props: TextProps) => props.mt}px;
//   margin-right: ${(props: TextProps) => props.mr}px;
//   margin-bottom: ${(props: TextProps) => props.mb}px;
//   margin-left: ${(props: TextProps) => props.ml}px;
//   overflow: ${(props: TextProps) => props.overflow};
//   ${(props: TextProps) => getEllipsis(props.ellipsis)}
//   word-break: break-all;
// `
// P1.defaultProps = {
//   color: color.black,
//   align: 'left',
//   mt: 0,
//   mr: 0,
//   mb: 0,
//   ml: 0,
//   overflow: 'visible',
// }

// export const P2 = styled(P1)`
//   font-size: 1.4rem;
// `

// export const P3 = styled(P1)`
//   font-size: 1.2rem;
// `

export const H1 = styled.h1`
  font-size: 1.8rem;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  font-family: ${(props) => props.serif && 'Noto Serif JP'};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`

const hDefaultProps = {
  color: color.black,
  align: 'left',
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
}
H1.defaultProps = hDefaultProps

export const H2 = styled.h2`
  font-size: 1.4rem;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  font-family: ${(props) => props.serif && 'Noto Serif JP'};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
H2.defaultProps = hDefaultProps

export const H3 = styled.h3`
  font-size: 1.2rem;
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  font-family: ${(props) => props.serif && 'Noto Serif JP'};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
H3.defaultProps = hDefaultProps

// export const H4 = styled.h4`
//   font-size: 1rem;
//   font-weight: 600;
//   color: ${(props: TextProps) => props.color};
//   text-align: ${(props: TextProps) => props.align};
//   font-family: ${(props) => props.serif && 'Noto Serif JP'};
//   margin-top: ${(props: TextProps) => props.mt}px;
//   margin-right: ${(props: TextProps) => props.mr}px;
//   margin-bottom: ${(props: TextProps) => props.mb}px;
//   margin-left: ${(props: TextProps) => props.ml}px;
//   ${(props: TextProps) => getEllipsis(props.ellipsis)}
//   word-break: break-all;
// `
// H4.defaultProps = hDefaultProps

export const Span = styled.span`
  width: ${(props: TextProps) => props.width};
  font-size: ${(props: TextProps) => props.size}rem;
  font-weight: ${(props: TextProps) => props.weight};
  color: ${(props: TextProps) => props.color};
  text-align: ${(props: TextProps) => props.align};
  font-family: ${(props) => (props.serif ? 'Noto Serif JP' : 'sans-serif')};
  margin-top: ${(props: TextProps) => props.mt}px;
  margin-right: ${(props: TextProps) => props.mr}px;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  margin-left: ${(props: TextProps) => props.ml}px;
  ${(props: TextProps) => getEllipsis(props.ellipsis)}
  word-break: break-all;
`
Span.defaultProps = {
  size: 1.6,
  weight: 300,
  color: color.black,
  align: 'left',
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
}

export const SubTitle = styled(H3)`
  color: ${color.green};
  font-size: 1.8rem;
  margin-bottom: 30px;
  font-family: 'Noto Serif JP';
`

export const SpanTitle = styled(Span)`
  font-size: 2.6rem;
  margin-bottom: 50px;
  font-family: 'Noto Serif JP';
  @media screen and (max-width: 375px) {
    margin-bottom: 30px;
  }
`

export const SectionTitle = styled(H2)`
  font-size: 3rem;
  margin-bottom: 10px;
  font-family: 'Noto Serif JP';
  font-weight: 300;
`

export const SectionSpan = styled(Span)`
  font-size: 1.6rem;
  margin-bottom: ${(props: TextProps) => props.mb}px;
  font-family: 'Noto Serif JP';
`
SectionSpan.defaultProps = {
  mb: 60,
}

export const PageTitle = styled(H1)`
  font-size: 2rem;
  font-weight: 300;
  writing-mode: vertical-rl;
  letter-spacing: 10px;
  margin-bottom: 100px;
  font-family: 'Noto Serif JP';
  @media screen and (min-width: 768px) {
    margin-bottom: 200px;
  }
`
