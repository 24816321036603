interface color {
  [key: string]: string
}

const Color: color = {
  green: '#556052',
  black: '#444',
  gray1: '#999' /*文字*/,
  gray2: '#d8d8d8' /*border*/,
  gray3: '#F0EFEB' /*背景*/,
  shadow: 'rgba(0, 0, 0, 0.2)',
}
export default Color
