import React from 'react'
// import { Link } from 'gatsby'
// import { CartIcon, InstaIcon, FacebookIcon } from '~/images'
import { FacebookIcon, InstaIcon } from '~/images'
import ShareButton from '../shareButton'
import { Icons, MenuContainer } from './style'

const RightNav = () => {
  return (
    <MenuContainer>
      <Icons>
        {/* <Link to={'/cart'}>
          <CartIcon />
        </Link> */}
        <a
          href={'https://www.instagram.com/ajisaishizenmura/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstaIcon />
        </a>
        <a
          href={'https://www.facebook.com/ajisaisizennmurahappypork/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
        <ShareButton />
      </Icons>
    </MenuContainer>
  )
}
export default RightNav
